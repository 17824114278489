.ProjectsDetails {
    display: flex;
    flex-direction: column;
    gap: max(60px, 3vw);



    .nav {
        background-color: #fff;
        .logo_container {
          content: url("../../assets/shared/colorLogo.svg");
        }
    
        .inner-nav-link {
          color: #000 !important;
        }
    
        .CallCard {
          .icon {
            filter: brightness(0);
          }
    
          .right {
            * {
              color: #000;
            }
          }
        }
    
        .darkMode {
          filter: brightness(0);
        }
    
        .MobileNav {
          
    
          svg {
            color: #000 !important;
          }
    
          .left_mobile{
          * {
            color: #fff !important;
          }
          }
    
    
       
        }
    
      }
    






    .ProjectsDetails__baner {
        width: 100%;
        aspect-ratio: 1/0.225;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/ProjectsDetails/image2.png');
        background-attachment: fixed;
        position: relative;
        margin-top: max(85px, 4.25vw);

        
        .OurProjectsCard__inside {
            gap: max(20px, 1vw);
            padding: max(20px, 1vw) max(30px, 1.5vw);
            border-top: 6px solid #e2b10f;
            background-color: rgba(38, 38, 38, 0.79);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: all 250ms ease;
            opacity: 1;
            position: absolute;
            top: max(20px, 1vw);
            right: max(20px, 1vw);
        
            .with27 {
              font-size: max(15px, 1.35vw);
              font-family: "r-bold";
              color: #fff;
            }
        
            .with18 {
              font-size: max(12px, 0.9vw);
              font-family: "r-regular";
              display: flex;
              align-items: center;
              gap: 2px;
              color: #fff;
        
              svg {
                color: #fff;
              }
            }
          }
        @media (max-width: 1300px) {
            aspect-ratio: none;
            padding: max(150px, 7.5vw) 0;
            
        }
    }

    .ProjectsDetails__2 {
        display: flex;
        padding: 0 8%;
        
        .left {
            display: flex;
            flex-direction: column;
            gap: max(60px, 3vw);
            width: 50%;

            .titleAndText {
                display: flex;
                flex-direction: column;
                gap: max(45px, 2.25vw);

            }

            .locationAndContry {
                display: flex;
                flex-direction: column;
                gap: max(15px, 0.75vw);
            }

            .numberOfUnits{
                display: flex;
                flex-direction: column;
                gap: max(15px, 0.75vw);
            }


            .meter {
                display: flex;
                align-items: center;
                gap: max(15px, 0.75vw);
            }
        }

        .right {
            width: 50%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;

            .rightInside {
                width: 100%;
                height: 100%;
                background-image: url('../../assets/ProjectsDetails/construction1.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            gap: max(50px, 2.5vw);
            .left {
                width: 100%;
            }

            .right {
                width: 100%;
            }
            
        }
    }


    .ProjectsDetails__3 {
        padding: 0 4%;

        .featured-carousel {
            @media (max-width: 550px) {
            padding: 0;
    
            .sc-gueXAH {
                .OurProjectsCard {
                margin: 0;
                }
            }
            }
        }
    
        .rec-arrow-left,
        .rec-arrow-right {
            display: block;
            background-color: transparent !important;
            box-shadow: none;
            color: #1c1c74;
    
            &:hover {
            color: #5252a1 !important;
            box-shadow: none;
            }
        }
    
        .cpuUqm:hover:enabled,
        .cpuUqm:focus:enabled {
            color: #5252a1 !important;
            background-color: rgba(103, 58, 183, 1);
            box-shadow: none;
        }
    
        .sc-gueXAH {
            .OurProjectsCard {
            margin: 0 max(10px, 0.5vw);
            }
        }
    
        .sc-iAEzLF {
            display: none;
        }
    
        .sc-kAyceB {
            display: none;
        }
    }

    .insideDetails {
        width: 90%;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 730px) {
            width: 100%;
        }
    }





    .ProjectsDetails__4 {
        display: flex;
        flex-direction: column;
        gap: max(80px, 4vw);
        margin-bottom: max(80px, 4vw);

        .with47 {
            font-size: max(35px, 2.35vw);
            font-family: 'r-bold';
            text-align: center;
            
        }

        .ProjectsDetails__cards {
            display: flex;
            gap: max(20px, 1vw);
            margin: 0 auto;
            padding: 0 4%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .ProjectsDetailsCard {
                padding: max(25px, 2.5vw);
                display: flex;
                flex-direction: column;
                gap: max(20px, 1vw);
                align-items: flex-start;
                border: 1px solid #DBDBDB;


                .iconDetails{
                    height: max(80px, 4vw);
                }

                .iconDetails1 {
                    content: url('../../assets/ProjectsDetails/details1.svg');
                }

                .iconDetails2 {
                    content: url('../../assets/ProjectsDetails/details2.svg');
                }

                .iconDetails3 {
                    content: url('../../assets/ProjectsDetails/details3.svg');
                }

                .iconDetails4 {
                    content: url('../../assets/ProjectsDetails/details4.svg');
                }

                .with24 {
                    font-size: max(24px, 1.2vw);
                    font-family: 'r-semibold';
                }

                .with16 {
                    font-size: max(16px, 0.8vw);
                    font-family: 'r-regular';
                    line-height: max(33px, 1.65vw);
                }
            }

            @media (max-width: 1200px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 650px) {
                grid-template-columns: repeat(1, 1fr);
            }

        }
    }








    .with19 {
        font-size: max(19px, 0.9vw);
        font-family: 'r-bold';
    }

    .with19Regular {
        font-size: max(19px, 0.9vw);
        font-family: 'r-regular';
    }


    .with17 {
        font-size: max(17px, 0.85vw);
        font-family: 'r-bold';
    }

    .with18 {
        font-size: max(18px, 0.9vw);
        font-family: "r-regular";
        line-height: max(33px, 1.85vw);
    }

    .with62 {
        font-size: max(45px, 3.1vw);
        font-family: "r-semibold";
    }
}




body.dark-mode {
    .ProjectsDetails {
        .nav {
            background-color: #060619;
      
            .logo_container {
              content: url("../../assets/shared/logo.svg");
            }
      
            .inner-nav-link {
              color: #fff !important;
            }
      
            .CallCard {
              .icon {
                filter: brightness(100%);
              }
      
              .right {
                * {
                  color: #fff;
                }
              }
            }
      
            .darkMode {
              filter: brightness(100%);
            }
          }
    }
}