.CoursesMenuMobile {
  width: 100%;

  .itemMobile {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .main {
      border-bottom: 1px solid #000;
      font-size: 12px;
    }

    .menu-main-link {
      .block {
        color: #000;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: max(14px, 1.2vw);

        h6 {
          font-size: max(12px, 1vw);
        }
      }
    }

    .main {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px 0px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .projectMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;

    .icon-container {
      width: 90%;
      aspect-ratio: 1/1;

      .projectIcon {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(100%);
      }
    }
  }
}
