.ServicesCard {
  aspect-ratio: 1/1.09 !important;
  padding-left: max(40px, 3vw);
  padding-bottom: max(50px, 3.5vw);
  padding-right: max(30px, 1.5vw);
  padding-top: max(35px, 1.75vw);
  position: relative;
  display: flex;
  border: 1px solid rgba(219, 219, 219, 0.3);
  cursor: pointer;
  transition: all 250ms linear;

  .ServicesCardFilter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    display: none;
    z-index: 2;
  }

  .ServicesCardBgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 28, 28, 0.3);
    display: none;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inRightServices {
    display: flex;
    flex-direction: column;
    gap: max(50px, 2.5vw);
    align-self: flex-end;
  }

  .serviceIcon {
    width: 30%;
    content: url("../../../assets/WhatWeDo/whatWeDo1.svg");
  }

  p {
    font-size: max(24px, 1.2vw);
    font-family: "r-semibold";
    height: max(80px, 4vw);

  }

  .inRightTop {
    width: max(72px, 3.5vw);
    height: max(72px, 3.5vw);
    width: 25%;
    height: 25%;
    aspect-ratio: 1/1 !important;
    border-radius: 100% !important;
    background-color: #e2b10f;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: max(20px, 1vw);
    z-index: 3;
    display: none;

    svg {
      width: max(50px, 2.5vw);
      height: max(50px, 2.5vw);
      
      color: #fff;
    }

    &::before {
      position: absolute;
      content: url("../../../assets/shared/learnMore.svg");
      width: max(30px, 1.5vw);
      right: 120%;
    }
  }

  &:hover {
    transition: all 250ms linear;

    .ServicesCardFilter {
      display: block;
    }

    .ServicesCardBgImage {
      display: block;
    }

    color: #fff;

    .inRightServices {
      position: relative;
      z-index: 3;

      .serviceIcon {
        filter: brightness(1000%);
      }
    }

    .inRightTop {
      display: flex;
    }
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}