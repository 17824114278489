.nav {
    position: fixed;
    background-color: transparent;
    top:0;
    right:0;
    width: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0.2vw 2.5%;
    font-weight: 600;

    .left_side{
        display: flex;
        align-items: center;
    }
}

.CallCard {
    display: flex;
    align-items: center;
    gap: max(15px, 0.75vw);
    width: max-content;

    .left {
        width: max(30px, 2.25vw);
        height: max(30px, 2.25vw);
        aspect-ratio: 1/1 !important;
        border-radius: 100% !important;
        border: 1px solid #fff;
        display: flex;
        background: rgba(255, 255, 255, 0.27);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        .icon{
            width: max(12.5px, 0.85vw);
            content: url('../../../assets/shared/phone.svg');
        }
    }


    .right {
        display: flex;
        flex-direction: column;
        gap: max(10px, 0.5vw);
        span {
            font-family: 'r-regular';
            font-size: max(9px, 0.55vw);
            text-transform: uppercase;
            color: #fff;
            font-weight: 100;
        }

        p {
            width: 100%;
            font-family: 'r-bold';
            font-size: max(13px, 0.85vw);
            color: #fff;
            font-weight: bold;
        }
    }

    @media (max-width: 500px) {
        width: 100% !important;
    }
}

.inRightSideFooter {
    @media (max-width: 500px) {
        width: 100%;
    }
}

.nav .nav_logo{
    display: flex;
    align-items: center;
    .logo_container{
        content: url('../../../assets/shared/logo.svg');
        width: max(110px, 5.5vw);
        position: relative;
        z-index: 9999;
    }
}


.nav .nav_links{
    display: flex;
    align-items: center;
    .nav-link{
        position: relative;
        margin:0 max(2.5px, 0.7vw);

        .inner-nav-link {
            font-size: max(16px, 0.8vw);
            font-family: 'r-medium';
            font-weight: 100;
            position: relative;
            color: #fff;

            &:hover {

                &::before {
                    content: "";
                    width: 70%;
                    height: 0;
                    border-top: 3px solid #E2B10F;
                    position: absolute;
                    top: max(5px, 0.25vw);
                }

            }
        }

        &:hover{
            .nav_dropdown{
                display: flex;
            }
            .inner-nav-link svg{
                transform: rotate(-180deg);
            }
            .megamenu-arrow{
                display: block;
            }
        }
    }
    .megamenu-arrow{
        color: #fff;
        position: absolute;
        font-size: 0.9vw;
        top:3.8vw;
        right: 2.9vw;
        display: none;
        @media(max-width:1200px){
            top:42.5px
        }
    }
    .line{
        display: none;
        position: absolute;
        top: 20%;
        width: max(1px, 0.8vw);
        height: max(1px, 0.8vw);
        left: -5%;
        margin-left: auto;
        margin-right: auto;
        transition: 0.3s ease-in-out;
        border-left: max(1px , 0.2vw) solid green;
        border-top: max(1px , 0.2vw) solid green;
        background-color: transparent;
    }

    .line_right{
        display: none;
        position: absolute;
        top: 20%;
        width: max(1px, 0.8vw);
        height: max(1px, 0.8vw);
        right: -5%;
        margin-left: auto;
        margin-right: auto;
        transition: 0.3s ease-in-out;
        border-right: max(1px , 0.2vw) solid green;
        border-top: max(1px , 0.2vw) solid green;
        background-color: transparent;
    }
    .inner-nav-link{
        color:#000;
        display: flex;
        align-items: center;
        padding:max(1.85vw, 20px) 0.5vw;
        font-size: max(12.5px, 0.9vw);
        font-size: max(13px, 1vw);
        position: relative;
        z-index: 2;
        svg{
            font-size: max(16px, 1.1vw);
            margin-left: max(2px, 0.15vw);
            transition: 0.15s ease-in-out;
            -webkit-transition: 0.15s ease-in-out;
        }
    }
}


.nav__sticky{
    // background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
    background: rgba(6, 6, 25, 1);

    .menu-button, .nav-links .inner-nav-link{
        color:#111;
    }

    .nav-auth .contact-us{
        border:1px solid var(--main-color);
        color: var(--main-color);
    }
}



.courses-link {
    &:hover{
        .courses-megamenu{
            display: flex !important;
        }
    }

}

.nav-link {
    &:hover {
        .line {
            display: block;
        }

        .line_right {
            display: block;
        }
    }
}

.nav .nav_auth{
    display: flex;
    align-items: center;
    gap: max(20px, 1vw);
    
}



.nav .nav_auth .apply_now {
    color:#fff;
    font-size: max(16px, 0.8vw);
    font-family: 'r-medium';
    height: max(46.4px, 2.9vw);
    padding: 0 max(30px, 1.5vw);

    border-radius: 100vmax !important;
    background-color: #E2B10F;
    font-weight: 100;
}

.MobileNav {
    display: none;
}

@media screen and (max-width: 1024px) {
    .nav {
        
        .nav_links {
            display: none;
        }

        .nav_auth {
            display: none;
        }
    }

    .MobileNav {
        display: block;
    }
    
}