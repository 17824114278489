.nav .whatWeDoNav {
  position: fixed;
  background: #fff;
  top: 4.75vw;
  overflow: hidden;
  display: none;
  justify-content: space-between;
  padding: max(15px, 1.5vw);
  border-radius: max(10px, 0.8vw);
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.15) 0px 3px 7px -3px;
  gap: max(20px, 1vw);
  left: max(10%, 20vw);

  .menu-links {
    display: flex;

    .inner-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

body.dark-mode .whatWeDoNav {
  background-color: #212422;
}

.nav .whatWeDoNav .menu-main-link {
  display: flex;

  border: 1px solid transparent;
  overflow: hidden;
  border-radius: max(15px, 2vw);
  transition: 250ms all ease-in-out;

  .main {
    background: transparent;
    width: 100%;
    position: relative;
    z-index: 3;
    flex: 1;
    padding: max(7px, 0.5vw) max(10px, 1vw);
    display: flex;
    align-items: center;
    outline: none !important;
    height: max(90px, 8vh);
    transition: all 250ms linear !important;

    &:hover {
      .arrow45 {
        display: block;
        opacity: 1;
      }

      .icon-container {
        background-color: #1c1c74;

        .icon {
          filter: brightness(10000%);
        }
      }
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    transition: 0.35s ease-in-out;
    -webkit-transition: 0.35s ease-in-out;
    clip-path: polygon(
      calc(100% - max(24px, 2vw)) 0,
      100% max(19px, 1.7vw),
      100% 100%,
      0 100%,
      0 0
    );
  }

  .icon-container {
    width: max(60px, 3vw);
    max-height: max(60px, 3vw);
    margin-right: max(12.5px, 1vw);
    display: flex;
    align-items: center;
    aspect-ratio: 1/1 !important;
    border-radius: 100% !important;
    border: 1px solid #1c1c74;
  }

  .icon {
    width: max(25px, 1.5vw);
  }

  .block {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    h6 {
      margin: auto 0;
      font-size: max(13px, 0.8vw);
      font-family: "r-semibold";
      width: 100% !important;
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: max(10px, 0.5vw);
    }

    gap: max(10px, 0.5vw);

    .arrow45 {
      width: max(20px, 1.5vw);
      content: url("../../../../../assets/WhatWeDo/arrow45.svg");
      opacity: 0;
    }
  }

  h6 {
    font-size: max(12px, 0.8vw);
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    margin-bottom: max(3px, 0.6vw);
  }

  .block p {
    font-size: max(10px, 0.65vw);
    color: #3e3e3e;
    font-weight: 400;
    width: 80%;
  }
}

body.dark-mode .block {
  h6 {
    color: #fff !important;
  }

  p {
    color: #fff !important;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: max(20px, 1vw);

  .rightImage {
    width: max(190px, 9.5vw);
    content: url("../../../../../assets/WhatWeDo/smallImage.webp");
  }

  .with24 {
    font-size: max(24px, 1.2vw);
    font-family: "r-semibold";
    color: #000;
    text-wrap: balance;
  }

  .with16 {
    font-size: max(16px, 0.8vw);
    font-family: "r-regular";
    color: #000;
  }

  a {
    width: max(144px, 7.2vw);
    height: max(43px, 2.15vw);
    border-radius: 100vmax !important;
    color: #fff;
    font-size: max(15px, 0.75vw);
    font-family: "r-medium";
    font-weight: lighter;
    background-color: #e2b10f;
  }
}

body.dark-mode .right {
  h1 {
    -webkit-text-stroke: max(1px, 0.1vw) #fff;
  }
}

body.dark-mode {
  .whatWeDoNav {
    background-color: #08081d;

    .icon-container {
      border-color: #fff !important;

      .icon {
        filter: brightness(1000%);
      }
    }

    .main {
      &:hover {
        .icon-container {
          background-color: #5757ec !important;
        }

        .block {
          h6 {
            .arrow45 {
              filter: brightness(200%) !important;
            }
          }
        }
      }
    }
  }
}


body.dark-mode {
  .right {
    .with24,
    .with16 {
      color: #fff;
    }
  }
}