.select-language {
  display: flex;
  flex-direction: row;
  gap: max(5px, 0.25vw);
  align-items: center;
  // width: max(92px, 4.6vw) !important;
  width: fit-content !important;
  
  .arrow {
    width: max(20px, 1vw);
    height: max(20px, 1vw);
  }
}

.select-language .single-language {
  display: flex;
  width: 100%;
}


.select-language .single-language .flag {
  width: 40px;
  display: flex;
  align-items: center;
}
.select-language .single-language .text {
  color: gray;
  flex: 1;
  font-size: max(14px, 0.7vw);
  font-family: 'mo-medium';
}
.select-language .single-language:hover {
  cursor: pointer;
  background-color: var(--shadow);
}
.select-language .single-language.selected {
  background-color: var(--shadow);
}

.select-language {
  .selectText {
    font-size: max(14px, 0.7vw);
    font-family: 'mo-medium';
  }
}
.select-language .dropdown {
  position: absolute;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  gap: max(10px, 0.5vw);
  background-color: #111;
  border: 1px solid #fff;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s linear;
  border-bottom: none;
  visibility: hidden;
  padding: 5px 0px;
  width: max(92px, 4.6vw);
  width: 100%;
}
.select-language:hover {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid transparent;
}
.select-language:hover .dropdown {
  max-height: 700px;
  overflow-y: auto;
  visibility: visible;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #a1aec4;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aeb8c9;
    border-radius: 3px;
  }
}
.select-language img {
  width: 35px;
}

@media(max-width:1475px){
  .select-language {
    padding: 6px;
    width: 145px;
  }

  .select-language .single-language {
    padding: 2.5px 5px 3px;
    margin:3px 0;
  }
  .select-language .single-language .flag {
    width: 35px;
  }
  .select-language .dropdown {
    bottom: 33px;
    padding: 4px 0px;
  }
  .select-language img {
    width: 30px;
  }
}

@media(min-width:1500px){
.select-language:hover .dropdown {
  max-height:700px;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
}
}


.select-language {
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .single-language {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: max(5px, 0.25vw);


    .flag {
      width: fit-content !important;
    }
  }

img {
  width: max(20px, 1vw);
  height: max(15px, 0.75vw);
}

.dropdown {
  .text {
    span {
      font-size: max(14px, 0.7vw);
      font-family: 'mo-medium';
    }
  }
}
}

@media (max-width: 768px) {
.select-language {
  .dropDownOpen {
    display: flex;
  }

  .dropDownClose {
    display: none;
  }

}

}