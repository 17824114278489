.buildAndYear {
  display: flex;
  flex-direction: column;
}

.BuildingYourFeature {
  width: 100%;

  background-image: url("../../../assets/Home/BuildingYourFeature/buildingYourFeatureBg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: max(140px, 7vw) 0;
  position: relative;

  .BuildingYourFeature__filter {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      270deg,
      rgba(17, 17, 19, 0.39) 0%,
      rgba(14, 14, 46, 1) 100%
    );
  }

  .BuildingYourFeature__inside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: max(60px, 3vw);
    color: #fff;
    position: relative;
    z-index: 2;
    padding-left: 8%;

    .with62 {
      font-size: max(62px, 3.1vw);
      font-family: "r-semibold";
      text-align: center;

      @media (max-width: 750px) {
        font-size: max(50px, 2.5vw);
      }
    }

    .with20 {
      line-height: max(37px, 1.85vw);
      width: 60%;

      @media (max-width: 1000px) {
        width: 70%;
      }

      @media (max-width: 850px) {
        width: 80%;
      }

      @media (max-width: 750px) {
        width: 90%;
      }
    }

    .BuildingYourFeature___line {
      width: 17.5%;
      min-width: max(150px, 7.5vw);
      height: 0;
      border-top: 4px solid #fff;
    }

    .with37 {
      font-size: max(37px, 1.85vw);
      font-family: "r-regular";
    }

    .ceo {
      display: flex;
      flex-direction: column;
      gap: max(20px, 1vw);

      .with18 {
        font-size: max(18px, 0.9vw);
        font-family: "r-bold";
      }

      .with14 {
        font-size: max(14px, 0.7vw);
        font-family: "r-regular";
      }
    }
  }
}

body.dark-mode {
  .BuildingYourFeature__filter {
    background: linear-gradient(
      90deg,
      rgba(17, 17, 19, 0.7) 0%,
      rgba(14, 14, 46, 0.23) 100%
    );
  }
}
