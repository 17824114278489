.OurProjects {
  display: flex;
  flex-direction: column;
  gap: max(50px, 2.5vw);

  .OurProjects__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: max(30px, 1.5vw);
    padding: 0 max(10px, 0.5vw);

    margin: 0 auto;

    .with18Yellow {
      text-align: center;
      font-size: max(18px, 0.9vw);
      color: #e2b10f;
      text-transform: uppercase;
      font-family: "r-bold";
    }

    .with62 {
      font-size: max(62px, 3.1vw);
      font-family: "r-semibold";
      text-align: center;

      @media (max-width: 750px) {
        font-size: max(50px, 2.5vw);
      }
    }

    .with20 {
      text-align: center;
      font-size: max(18px, 0.9vw);
      line-height: max(33px, 1.75vw);
    }

    @media (max-width: 1380px) {
      width: 85%;
    }

    @media (max-width: 1000px) {
      width: 95%;
    }
  }

  .OurProjects__mid {
    a {
      width: 90%;
      .OurProjectsCard {
        width: 100%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }
    .featured-carousel {
      @media (max-width: 550px) {
        padding: 0;

        .sc-gueXAH {
          .OurProjectsCard {
            margin: 0;
          }
        }
      }
    }

    .rec-arrow-left,
    .rec-arrow-right {
      display: block;
      background-color: transparent !important;
      box-shadow: none;
      color: #1c1c74;

      &:hover {
        color: #5252a1 !important;
        box-shadow: none;
      }
    }

    .cpuUqm:hover:enabled,
    .cpuUqm:focus:enabled {
      color: #5252a1 !important;
      background-color: rgba(103, 58, 183, 1);
      box-shadow: none;
    }

    .sc-gueXAH {
      .OurProjectsCard {
        margin: 0 max(10px, 0.5vw);
      }
    }

    .sc-iAEzLF {
      display: none;
    }

    .sc-kAyceB {
      display: none;
    }
  }

  .OurProjects__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: max(215px, 10.75vw);
      height: max(68px, 3.4vw);
      border-radius: 100vmax !important;
      color: #fff;
      font-family: "r-regular";
      font-size: max(15px, 0.75vw);
      background-color: #1c1c74;
      margin-top: max(30px, 1.5vw);
    }
  }
}
