.JustConstruct {
  width: 100%;
  padding: 0 8%;
  position: relative;
  display: flex;
  align-items: center;

  .bigMan {
    width: 100%;
    content: url("../../../assets/Home/JustConstruct/justConstruct.svg");

    @media (max-width: 700px) {
      bottom: 0;
      right: max(10px, 0.5vw);
    }
  }

  .with72 {
    font-size: max(32px, 3.6vw);
    font-family: "r-regular";
    color: #1c1c74;
    position: absolute;
    width: 40%;

    @media (max-width: 700px) {
      font-size: max(22px, 1.1vw);
      width: 50%;
    }

    @media (max-width: 450px) {
      font-size: max(16px, 1vw);
      width: 60%;
    }
  }

  @media (max-width: 700px) {
    margin-top: max(50px, 2.5vw);
  }
}

body.dark-mode {
    .bigMan {
        filter: brightness(1000%);
    }

    .with72 {
        color: #fff;
    }
}