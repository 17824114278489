.HomeBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    aspect-ratio: 1/0.55;
    background-position: top;

    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1s ease, background-position 1s ease; /* Adjusted transition properties */
    background-attachment: fixed;
    
    @media (max-width: 1150px) {
      aspect-ratio: auto;
      padding: max(100px, 5vw) 0;
      background-position: left;   
    }
  
    .HomeBanner__filter {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(14, 14, 96, 0.32);
    }
  
    .HomeBanner__filter__hide {
      display: none;
    }
  
    .HomeBanner.slide-from-left {
      background-position: -100% 0;
    }
  
    .insiderHomeBanner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: max(35px, 1.75vw);
      text-align: center;
      width: 90%;
      position: relative;
      z-index: 2;
  
      .with100 {
        font-size: max(80px, 5vw);
        font-family: "r-semibold";
        color: #fff;
        text-wrap: balance;
  
        @media (max-width: 620px) {
          font-size: max(60px, 3vw);
        }
      }
  
      .with20 {
        font-size: max(20px, 1vw);
        font-family: "r-medium";
        color: #fff;
        width: max(600px, 30vw);
        span {
          font-family: "r-bold";
        }
  
        @media (max-width: 600px) {
          width: 95%;
        }
      }
  
      a {
        display: flex;
        align-items: center;
        justify-content: center;
  
        font-size: max(15px, 0.75vw);
        font-family: "r-regular";
        color: #fff;
        gap: max(20px, 1vw);
        width: max(215px, 10.75vw);
        height: max(68px, 3.4vw);
        border-radius: 100vmax !important;
        background-color: #1C1C74;
  
        .arrow45 {
          width: max(20px, 1vw);
          content: url("../../../assets/WhatWeDo/arrow45.svg");
          opacity: 1;
          filter: brightness(10000%);
        }
      }
    }
  }
  

  body.dark-mode {
    .HomeBanner__filter {
        background-color: rgba(2, 2, 33, 0.8);
    }
  }