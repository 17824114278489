.MobileNav {
    padding: max(10px, 1vw);
    height: 70px;
    display: flex;
    align-items: center;
    
    .handleClick {
        position: relative;
        z-index: 9999;
        cursor: pointer;

        svg {
            color: #fff;
        }
    }

    .bgMobile {
        background-color: rgba(0,0,0, 0.9);
    }

    .left_mobile {
        background-color: #060619;


        * {
            color: #fff !important;
        }
    }



    .openMenuMobile {
        display: flex;
    }

    .closeMenuMobile {
        display: none;
    }    

    .left_mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid #000;
        padding-top: 50px;
    }

    .bgMobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9); /* Adjust background color as needed */
        z-index: 999; /* Ensure it's above other elements */
      }


    .left_mobile {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 70%;
        max-width: 300px;
        z-index: 2;
        display: flex;
        overflow-y: scroll;

        @media (max-width: 450px) {
            max-width: none;
            width: 100%;
            
        }
    }


    .nav_links {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: max(1px, 0.1vw) solid #b8b8b8;
        position: relative;
        top: 3%;
        margin-bottom: max(50px, 2.5vw);

        .nav-link {

            align-self: flex-start;
            width: 100%;
            margin: 0;

            .inner-nav-link {
                font-size: max(13px, 1vw);

                &:hover {
                    &::before {
                        display: none;
                    }
                }
            }

            .line {
                display: none;
            }

            .line_right {
                display: none;
            }
        }
    }

    .nav_auth {
        display: flex;
        flex-direction: column;
        

        .darkMode {
            width: 30px;
            height: 30px;
        }
    }

}

.showCourse {
        display: flex;
    .CoursesMenuMobile{
        display: flex !important;
    }
}


.showCourse {
    display: flex;
    .CoursesMenuMobile{
        display: none !important;
    }
}

.showCourse .CoursesMenuMobile {
    display: flex !important;
}

.hideCourse .CoursesMenuMobile {
    display: none !important;
}


.MobileNav {
    
    .mobileNavFooter {
        padding: 0 max(10px, 1vw);
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid #000;
        margin-top: 10px;
        background-color: rgb(114, 113, 113);

        p {
            font-size: 12px;
        }

        .mobileFlex {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .darkMode {
                margin-bottom: 0px;
            }
        }

        .cursor-pointer {
            display: flex !important;
            align-items: center !important;
        }

        .select-language {
            width: 100px;
            border: none;

            .single-language {
                width: fit-content;
            }

            .flag {
                margin: 10px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin: 0 auto;
                }
            }

            .text {
                display: none;
            }
        }
    }
}


body.dark-mode {
    .left_mobile {
        background-color: #060619;
    }
}


@media screen and (max-width: 768px) {
    .nav_auth {

        .darkMode {
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
        }
        
        .apply_now {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 100vmax !important;
            background-color: #E2B10F;
        }
    }
    
}

@media screen and (min-width: 992px) {
    .MobileNav {
        display: none;
    }

}


#closeBtn {
    svg {
        font-size: 40px !important;
        color: #fff;
    }
}