.AboutUs {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .AboutUs__banner {
    display: flex;
    flex-direction: column;

    .AboutUs__first__banner {
      width: 100%;
      aspect-ratio: 1/0.4;
      background-image: url("../../assets/AboutUs/aboutUsBg.webp");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      position: relative;
      display: flex;
      align-items: center;

      .AboutUs__first__banner__filter {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background-color: rgba(14, 14, 96, 0.44);
      }

      .AboutUs__first__banner__inside {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
        padding-left: 8%;
        position: relative;
        z-index: 2;

        .with25 {
          font-size: max(25px, 1.25vw);
          font-family: "r-semibold";
          color: #fff;
          text-transform: uppercase;
        }

        .with80 {
          font-size: max(60px, 4vw);
          font-family: "r-semibold";
          color: #fff;
        }

        @media (max-width: 650px) {
          padding-left: 2%;

          .with25 {
            font-size: max(18px, 0.9vw);
          }

          .with80 {
            font-size: max(50px, 2.5vw);
          }
        }
      }

      @media (max-width: 900px) {
        aspect-ratio: auto;
        padding: max(150px, 7.5vw) 0;
      }
    }

    .AboutUs__second__banner {
      background-color: #0e0e2e;
      display: flex;
      padding: 4% 8%;
      gap: max(50px, 2.5vw);
      justify-content: space-between;

      .AboutTexts {
        display: flex;
        flex-direction: column;
        gap: max(40px, 2vw);
        width: 50%;

        p {
          color: #fff;
        }
      }

      .AboutUs__second__banner__right {
        padding: max(30px, 1.5vw);
        padding-bottom: max(80px, 4vw);
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
        background-color: #1c1c74;
        position: relative;
        width: 35%;

        &::before {
          content: "";
          position: absolute;
          width: max(60px, 3.35vw);
          height: max(60px, 3.35vw);
          aspect-ratio: 1/1 !important;
          border-radius: 100% !important;
          background-color: #e2b10f;
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%);
        }
        .onlyLogo {
          width: max(45px, 2.25vw);
          content: url("../../assets/AboutUs/onlyLogo.svg");
        }

        .with35 {
          font-size: max(35px, 1.75vw);
          font-family: "r-semibold";
          color: #fff;
        }

        .with20 {
          font-size: max(20px, 1vw);
          font-family: "r-medium";
          color: #fff;
          line-height: max(33px, 1.65vw);
        }
      }

      @media (max-width: 1000px) {
        flex-direction: column;
        padding-bottom: max(100px, 5vw);

        .AboutTexts {
          width: 100%;
        }

        .AboutUs__second__banner__right {
          width: 100%;
        }
      }
    }
  }

  .AboutUs__1 {
    display: flex;
    flex-direction: row-reverse;
    margin-top: max(80px, 4vw);

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: max(60px, 3.5vw);
      padding: 8%;

      .with25 {
        font-size: max(25px, 1.25vw);
        font-family: "r-semibold";
        text-transform: uppercase;
        text-align: start;
        width: 100%;
      }

      .insideLeftAbout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: max(70px, 3.5vw);
      }

      .aboutUsCard1 {
        display: flex;
        align-items: center;
        gap: max(30px, 1.5vw);
        align-self: flex-start;

        .aboutUsCard1__left {
          width: max(67px, 3.35vw);
          height: max(67px, 3.35vw);
          aspect-ratio: 1/1 !important;
          border-radius: 100% !important;
          background-color: #e2b10f;
          display: flex;

          .iconAbout1 {
            width: max(23px, 1.15vw);
            content: url("../../assets/AboutUs/aboutIcon1.svg");
          }

          .iconAbout2 {
            width: max(27px, 1.35vw);
            content: url("../../assets/AboutUs/aboutIcon2.svg");
          }

          .iconAbout3 {
            width: max(29px, 1.45vw);
            content: url("../../assets/AboutUs/aboutIcon3.svg");
          }

          .iconAbout4 {
            width: max(28px, 1.4vw);
            content: url("../../assets/AboutUs/aboutIcon4.svg");
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          gap: max(20px, 1vw);
          span {
            font-size: max(30px, 1.5vw);
            font-family: "r-semibold";
          }

          p {
            font-size: max(16px, 0.8vw);
            font-family: "r-medium";
            width: 100%;
          }
        }

        @media (max-width: 500px) {
          width: 100% !important;
        }

        @media (max-width: 390px) {
          flex-direction: column;
          text-align: center;
          .right {
            text-align: center;
            span {
              width: 100%;
            }
          }
        }
      }
    }

    .rightAbout1 {
      width: 50%;
      aspect-ratio: 1/1 !important;
      background-image: url("../../assets/AboutUs/about1.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .rightAbout1 {
        width: 100%;
      }
    }
  }

  .AboutUs__2 {
    display: flex;
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: max(70px, 3.5vw);
      padding: 4%;

      .aboutTitleAndText {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
      }

      .with40 {
        font-size: max(30px, 2vw);
        font-family: "r-regular";
        color: #1c1c74;
        line-height: max(50px, 2.5vw);
      }

      a {
        width: max(195px, 9.75vw);
        height: max(67px, 3.35vw);
        border-radius: 100vmax !important;
        color: #fff;
        font-family: "r-regular";
        font-size: max(15px, 0.75vw);
        background-color: #1c1c74;
        margin-top: max(30px, 1.5vw);
      }
    }

    .right {
      width: 50%;
      aspect-ratio: 1/1 !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .aboutUsCard2 {
        width: 70%;
        height: max(110px, 5.5vw);
        display: flex;
        align-items: center;
        gap: max(60px, 3vw);
        border: 1px solid #c8c8c8;
        padding-left: 6%;
        cursor: pointer;

        .with22 {
          font-size: max(22px, 1.1vw);
          font-family: "r-semibold";
        }

        .aboutUsIcon2__1__1 {
          width: max(60px, 3vw);
          content: url("../../assets/WhatWeDo/whatWeDo1.svg");
        }

        .aboutUsIcon2__1__2 {
          width: max(86px, 4.3vw);
          content: url("../../assets/WhatWeDo/whatWeDo3.svg");
        }

        .aboutUsIcon2__1__3 {
          width: max(57px, 2.85vw);
          content: url("../../assets/WhatWeDo/whatWeDo4.svg");
        }

        &:hover {
          transition: all 250ms linear;
          background-color: #1c1c74;
          .aboutUsIcon2__1 {
            filter: brightness(1000%);
          }

          .with22 {
            color: #fff;
          }
        }

        @media (max-width: 600px) {
          width: 90%;

          gap: max(30px, 1.5vw);

          .aboutUsIcon2__1 {
            width: 15%;
          }

          .with22 {
            font-size: max(16px, 0.8vw);
          }
        }
      }

      .aboutUsCard2__2 {
        position: relative;
        right: -10%;

        @media (max-width: 600px) {
          right: 0;
        }
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        aspect-ratio: auto !important;
        padding: max(100px, 5vw) 0;
      }
    }
  }

  .AboutUs__3 {
    display: flex;
    flex-direction: row-reverse;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: max(70px, 3.5vw);
      padding: 4%;

      .aboutTitleAndText {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
      }

      .with40 {
        font-size: max(30px, 2vw);
        font-family: "r-regular";
        color: #1c1c74;
        line-height: max(50px, 2.5vw);
      }
    }

    .right {
      width: 50%;
      aspect-ratio: 1/1 !important;
      background-image: url("../../assets/AboutUs/about2.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }
  }

  .AboutUs__4 {
    display: flex;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: max(70px, 3.5vw);
      padding: 4%;

      .aboutTitleAndText {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
      }

      .with40 {
        font-size: max(30px, 2vw);
        font-family: "r-regular";
        color: #1c1c74;
        line-height: max(50px, 2.5vw);
      }
    }

    .right {
      width: 50%;
      aspect-ratio: 1/1 !important;
      background-image: url("../../assets/AboutUs/about3.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }
  }

  .with18 {
    font-size: max(18px, 0.9vw);
    font-family: "r-regular";
    line-height: max(33px, 1.85vw);
  }

  .with62 {
    font-size: max(45px, 3.1vw);
    font-family: "r-semibold";
  }
}
