.News {
    .News__banner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        aspect-ratio: 1/0.24;
        position: relative;
        background-image: url('../../assets/News/newsBg.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        .News__banner__filter {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(14, 14, 96, 0.44);
        }

        .with62 {
            font-size: max(45px, 3.1vw);
            font-family: "r-semibold";
            color: #fff;
            position: relative;
            z-index: 2;
            text-align: center;
        }

        @media (max-width: 1300px) {
            aspect-ratio: auto;
            padding: max(150px, 7.5vw) 0;
            
        }
    }
}


.News__2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4%;
    margin: max(60px, 3vw) 0;

    .with40 {
        font-size: max(40px, 2vw);
        font-family: "r-semibold";
    }

    .latestNewest{
        width: max(134px, 6.7vw);
        display: flex;
        flex-direction: column;
        padding: max(12px, 0.6vw) 0;
        border-radius: 100vmax;
        gap: max(20px, 1vw);
        position: relative;
        border: 1px solid rgba(112, 112, 112, 0.2);
        cursor: pointer;


        .FullMenu {
            position: absolute;
            left: 0;
            top: 100%;

            border: 1px solid rgba(112, 112, 112, 0.2);
            width: 100%;
            padding: max(10px, 0.5) 0;
            display: flex;
            flex-direction: column;
            gap: max(10px, 0.5vw);
            background-color: #fff;
            display: none;
        }

        .openFull {
            display: flex;
        }

        .WeSee {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: max(20px, 1vw);
            gap: max(10px, 0.5vw);
            border-radius: 100vmax;
        }

        svg {
            &:first-child {
                color: #A7A7A7;
                width: max(20px, 1vw);
                height: max(20px, 1vw);
            }

            &:last-child {
                width: max(20px, 1vw);
                height: max(20px, 1vw);
            }
        }

        .rotateArrow {
            transform: rotate(180deg);
        }

        .newestIcon {
            rotate: 180deg;
            transform: scaleX(-1);
        }

        span {
            font-size: max(15px, 0.75vw);
            font-family: 'r-regular';
        }
        .rotate180deg {
            transform: rotate(180deg);
        }
    }

    @media (max-width: 500px) {
        flex-direction: column;
        gap: max(50px, 2.5vw);
    }
}



.News__3 {
    display: flex;
    gap: max(50px, 2.5vw);
    padding: 0 4%;
    margin-bottom: max(100px, 5vw);

    .News__3__left {
        width: 30%;
        flex-direction: column;

        .inputWithFull {
            width: max(200px, 22.5vw);
            height: max(63px, 3.15vw);
            border-radius: 100vmax;
            position: relative;
            display: flex;
            border: 1px solid #D4D4D4;
            overflow: hidden;

            input {
                border: none;
                outline: none;
                position: absolute;
                width: 100%;
                height: 100%;
                padding-left: max(60px, 3vw);
                font-size: max(15px, 0.75vw);
                font-family: 'r-regular';
            }

            .iconInput {
                position: absolute;
                z-index: 2;
                width: max(18px, 0.9vw);
                content: url('../../assets/shared/search.svg');
                top: 50%;
                left: max(10px, 0.5vw);
                transform: translate(50%, -50%);

            }
        }


        .with22 {
            font-size: max(22px, 1.1vw);
            font-family: 'r-semibold';
        }

        .Categories {
            display: flex;
            flex-direction: column;
            gap: max(20px, 1vw);
            margin-top: max(30px, 1.5vw);
            max-width: 350px;

            

            .categories__group {
                display: flex;
                flex-direction: column;
                gap: max(20px, 1vw);

                .with17Bg {
                    font-size: max(17px, 0.85vw);
                    font-family: 'r-medium';
                    cursor: pointer;
                    padding-left: max(30px, 1.5vw);
                }

                .with17BgActive {
                    background-color: #E2B10F;
                    height: max(45px, 2.25vw);
                    display: flex;
                    align-items: center;
                    padding-left: max(30px, 1.5vw);
                    color: #fff;
                    border-radius: 100vmax;
                    font-size: max(17px, 0.85vw);
                    font-family: 'r-medium';
                    cursor: pointer;
                }
            }
        }


        .yearAndMonth {
            margin-top: max(50px, 2.5vw);
            display: flex;
            gap: max(20px, 1vw);
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);            

            .divWithInput {
                display: flex;
                flex-direction: column;
                gap: max(10px, 0.5vw);
                width: 100%;
      
                label {
                  font-size: max(16px, 0.8vw);
                  font-family: "r-semibold";
                }
      
                .fullInput {
                  width: 100%;
                  height: max(56px, 2.8vw);
                  border-radius: 100vmax !important;
                  border: 1px solid rgba(112, 112, 112, 0.2);
                  position: relative;
                  overflow: hidden;
      
                  .iconInput {
                    position: absolute;
                    top: 50%;
                    left: max(25px, 1.25vw);
                    transform: translateY(-50%);
                  }
      
                  .iconInput1 {
                    width: max(11px, 0.55vw);
                    content: url("../../assets/ContactUs/user.svg");
                  }
      
                  .iconInput2 {
                    width: max(15px, 0.75vw);
                    content: url("../../assets/ContactUs/email.svg");
                  }
      
                  .iconInput3 {
                    width: max(14px, 0.7vw);
                    content: url("../../assets/ContactUs/phone.svg");
                  }
      
                  .iconInput4 {
                    width: max(15px, 0.75vw);
                    content: url("../../assets/ContactUs/briefcase.svg");
                  }
      
                  input,
                  select,
                  textarea {
                      position: absolute;
                      width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    font-size: max(14px, 0.7vw);
                    font-family: "r-regular";
                    padding-left: max(30px, 1.5vw);

                    &::placeholder {
                      color: #686868;
                    }
                  }
      
                  select {
                    padding-right: max(30px, 1.5vw); /* Adjust as needed */
      
                    width: 97.5%;
                    position: relative;
                  }
                }
      
                textarea {
                  border-radius: max(12px, 0.6vw) !important;
                  height: max(180px, 9vw) !important;
                  padding-left: max(30px, 1.5vw) !important;
                  padding-top: max(20px, 1vw);
                  border: 1px solid rgba(112, 112, 112, 0.2);
                  outline: none;
                }
              }
        }

        .followUs {
            margin-top: max(60px, 3vw);
            display: flex;
            flex-direction: column;
            gap: max(30px, 1.5vw);

            .insideFollow {
                display: flex;
                flex-direction: column;
                gap: max(30px, 1.5vw);
            }

            .followUsCard {
                width: max(300px, 15vw);
                aspect-ratio: 1/1;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: max(15px, 0.75vw);
                position: relative;

                .instagramIcon {
                    position: absolute;
                    width: 20%;
                    height: 20%;
                    top: max(20px, 1vw);
                    left: max(20px, 1vw);
                    aspect-ratio: 1/1 !important;
                    border-radius: 100% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;

                    svg {
                        width: 45%;
                        height: 45%;
                        color: #1C1C74;
                    }
                }
                
                
            }

            .followUsCard1 {
                background-image: url('../../assets/News/followUs1.webp');
            }

            .followUsCard2 {
                background-image: url('../../assets/News/followUs2.webp');
            }
        }
    }




    @media (max-width: 1000px) {
        flex-direction: column;    

        .News__3__left {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            display: flex;
            flex-direction: column;
            padding-bottom: max(60px, 3vw);
            border-bottom: 1px solid #1C1C74;

            .inputWithFull {
                width: 100%;

            }

            .yearAndMonth{
                width: 100%;
                .divWithInput {
                    width: 100%;
                }
            }

            .followUs {
                .insideFollow {
                    flex-direction: row;
                    .followUsCard {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 350px) {

         .followUs {
                .insideFollow {
                    flex-direction: column !important;
                    .followUsCard {
                        width: 100%;
                    }
                }
            }

            .yearAndMonth{
                .divWithInput {
                    width: 100%;
                    select {
                        padding-left: max(15px, 0.75vw) !important;
                    }
                }
            }
    }

    @media (max-width: 300px) {
        .yearAndMonth{
            flex-direction: column;
            .divWithInput {
                
                width: 100%;
                select {
                    padding-left: max(15px, 0.75vw) !important;
                }
            }
        }
        
    }

}


.News__3__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: max(50px, 2.5vw);
    width: 100%;
    

    .inside__3__right {
        display: flex;
        width: 100%;
        gap: max(35px, 1.75vw);
        flex-wrap: wrap;
    }

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        gap: max(20px, 1vw);
  
        li {
          font-size: max(16px, 0.8vw);
          a {
            display: flex;
            align-items: center;
          }
          cursor: pointer;
        }
      }
  
      .previous {
        svg {
          font-size: max(20px, 1vw);
          color: #1c1c74;
          cursor: pointer;
        }
      }
  
      .next {
        svg {
          font-size: max(20px, 1vw);
          color: #1c1c74;
          cursor: pointer;
        }
      }
  
      .pagination__link--active {
        width: max(43px, 2.15vw);
        height: max(43px, 2.15vw);
        aspect-ratio: 1/1 !important;
        border-radius: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1c1c74;
        color: #fff;
      }


    @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);

        
    }
}

.newsCard {
    width: 45%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: max(30px, 1.5vw);
    border: 1px solid transparent;
    border-radius: max(10px, 0.5vw);


    .top {
        width: 100%;
        aspect-ratio: 1/0.765;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/News/news1.webp');
        border-radius: max(10px, 0.5vw);
    }

    .bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: max(20px, 1vw);
        padding-bottom: max(30px, 1.5vw);
        .with15 {
            font-size: max(15px, 0.75vw);
            font-family: 'r-regular';
        }

        .with24 {
            font-size: max(18px, 1.2vw);
            font-family: 'r-semibold';
            line-height: max(34px, 1.7vw);
        }

        .with16Color {
            font-size: max(16px, 0.8vw);
            font-family: 'r-semibold';
            color: #1C1C74;
        }
    }

    &:hover {
        box-shadow: 0 0px max(10px, 0.5vw) rgba(28, 28, 116, 0.1);
        cursor: pointer;
    }
}





body.dark-mode {
    .News {
        .inputWithFull {

            input {
                background-color: #0E0E21 !important;
                color: #fff;
            }
        }

        .fullInput {
            background-color: #0E0E21 !important;
        }

        select {
            background-color: #0E0E21 !important;
            color: #fff;
        }

        
        .latestNewest {
            background-color: #0E0E21;
        }

        .FullMenu {
            background-color: #0E0E21 !important;

        }
    }

}




