.cursor-pointer {
  cursor: pointer;
}

.darkMode {
  width: max(34px, 1.7vw);
  height: max(34px, 1.7vw);
  aspect-ratio: 1/1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100vmax;
  transition: 300ms all ease-in-out;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max(17px, 0.85vw);
    height: max(17px, 0.85vw);

    .bgImgDark {
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/nav/moon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

body.dark-mode .darkMode {
  border: 1px solid #fff;
  transition: 300ms all ease-in-out;

  span {
    width: max(17px, 0.85vw);
    height: max(17px, 0.85vw);
    .bgImgDark {
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/nav/sunny-o.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
