.shared-footer {
  background: #1C1C23;
  color: #fff;
  padding: max(25px, 5%) 2.5% max(20px, 2vw);
  overflow-x: hidden;
  border-top: max(10px, 0.5vw) solid #1C1C74;

  .main-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: max(40px, 2vw);
  }

  @media (max-width: 1350px) {
    padding: 45px 5% 20px;
  }
}

.shared-footer a {
  &:hover {
    text-decoration: underline;
    scale: 1.05;
  }
}

.shared-footer .first-col {
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: max(30px, 1.5vw);

  .top {
    display: flex;
    flex-direction: column;
    gap: max(30px, 1.5vw);
  }

  .footerLogo {
    width: max(140px, 7vw);
    content: url("../../../assets/shared/logo.svg");
  }

  .yourDream {
    font-size: max(18px, 0.9vw);
    font-family: "r-regular";
  }

  h5 {
    font-size: max(14px, 0.875vw);
    font-weight: 500;
  }

  p {
    font-size: max(12.5px, 0.8vw);
    line-height: 1.5;
  }

  .links1 {
    display: flex;
  }

  .links1 a {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max(25px, 2vw);
    height: max(25px, 2vw);
    font-size: max(16px, 1.1vw);
    border-radius: 50%;
    margin-right: max(10px, 0.9vw);
    transition: 0.15s ease-in-out;
    -webkit-transition: 0.15s ease-in-out;

    &:hover {
      background: var(--main-color);
    }
  }

  .colors {
    margin-top: max(20px, 2vw);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h5 {
      font-weight: normal;
      font-size: max(14px, 0.9vw);
      text-transform: uppercase;
      margin: max(7.5px, 0.6vw) max(7.5px, 0.6vw) max(7.5px, 0.6vw) 0;
    }
  }
}

.shared-footer .all-footer-links {
  display: flex;
  justify-content: space-between;

  .footer-list {
    width: fit-content;
    max-width: 22%;
  }

  .links {
    display: flex;
    flex-direction: column;
  }

  h6 {
    font-size: max(14px, 1.1vw);
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: max(10px, 0.75vw);
  }

  a {
    color: #fff;
    font-size: max(13px, 0.85vw);
    margin: max(5px, 0.5vw) 0;
    line-height: 1.5;
    transition: 0.1s ease-in-out;
    -webkit-transition: 0.1s ease-in-out;

    &:hover {
      color: var(--main-color);
    }
  }
}

.shared-footer .last-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: max(30px, 3vw);

  p {
    font-size: max(13px, 0.825vw);
    margin: 15px;
    line-height: 1.7;
    text-align: center;
  }
}

.Subscribe {
  position: relative;
  display: flex;
  align-items: center;

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max(50px, 3vw);
    width: 90%;
    border-top-left-radius: 100vmax;
    border-bottom-left-radius: 100vmax;
    outline: none;
    border: none;
    padding-left: max(20px, 1.8vw);
    background-color: #333333;
    color: #fff;
  }

  button {
    position: absolute;
    right: 0%;
    height: max(50px, 3vw);
    width: max(137px, 30%);
    border-radius: 100vmax;
    font-size: max(16px, 1vw);
    cursor: pointer;
    background-color: #2ca253;
    color: #fff;
    outline: none;
    border: none;
  }
}

.Subscribe {
  .applyBgImage2Footer {
    width: 100%;

    span {
      position: absolute;
      left: max(20px, 1.5vw);
      width: max(14px, 1.2vw);
      height: max(14px, 1.2vw);
      z-index: 1;
      background-image: url("../../../assets/shared/apply2Dark.webp");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.links1 {
  display: flex;
  flex-direction: column;

  p {
    text-transform: uppercase;
  }

  div {
    display: flex;
    gap: max(10px, 0.5vw);

    img {
      height: max(25px, 1.5vw);
    }
  }
}

.lastFooterLink {
  display: flex;

  gap: max(30px, 2vw);

  h3 {
    text-transform: uppercase;
    font-size: max(14px, 0.8vw);
  }

  p {
    font-size: max(12px, 0.7vw);
  }
}

.inRightSideFooter {
  display: flex;
  flex-direction: column;
  gap: max(80px, 4vw) !important;

  
.CallCard {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: max(15px, 0.75vw);
  width: max-content;

  .left {
      width: max(30px, 2.25vw);
      height: max(30px, 2.25vw);
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      border: 1px solid #fff;
      display: flex;
      background: rgba(255, 255, 255, 0.27);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);

      .icon{
          width: max(12.5px, 0.85vw);
          content: url('../../../assets/shared/phone.svg');
      }

      .icon1{
        width: max(12.5px, 0.85vw);
        content: url('../../../assets/shared/email.svg');
    }

    .icon3{
      width: max(12.5px, 0.85vw);
      content: url('../../../assets/shared/location.svg');
  }
  }


  .right {
      display: flex;
      flex-direction: column;
      gap: max(10px, 0.5vw);
      span {
          font-family: 'r-regular';
          font-size: max(9px, 0.55vw);
          text-transform: uppercase;
          color: #fff;
          font-weight: 100;
      }

      p {
        width: max(150px, 7.5vw);
        font-family: 'r-bold';
          font-size: max(13px, 0.85vw);
          color: #fff;
          font-weight: bold;
      }
  }
}
}

.footerLink {
  display: flex;
  flex-direction: column;
  gap: max(20px, 1vw);

  h2 {
    font-size: max(17px, 0.85vw);
    text-transform: uppercase;
    font-family: 'r-semibold';
  }

  div {
    display: flex;
    flex-direction: column;

    a {
      font-size: max(15px, 0.75vw);
      font-family: 'r-regular';

    }
  }
}

.last-row {
  border-top: 1px solid #fff;

  div {
    display: flex;
    align-items: center;

    a {
      font-size: max(13px, 0.825vw);
      color: #fff;
    }
  }

  .makeBorder {
    :first-child {
      border-right: max(2px, 0.2vw) solid #fff;
      padding-right: max(20px, 0.4vw);
    }
  }
}

.top {
  img {
    height: max(31px, 2.2vw);
  }
}

.all-footer-links {
  flex: 1;
  gap: max(40px, 2vw);
}

.makeBorder {
  gap: 20px;
}

.lastFooterRight {
  display: flex;
  gap: max(20px, 1vw);

  p {
    font-size: max(14px, 0.7vw);
    font-family: "r-regular";
  }

  .select-language {
    width: max(100px, 8vw);
    height: max(40px, 2vw);
    font-size: max(10px, 1vw);
    border: 1px solid #707070;
    border-radius: 100vmax;
    padding: 0 max(10px, 0.5vw);
    display: flex;
    flex-direction: row;
    align-items: center;



    img {
      border-radius: 100% !important;
    }

    .dropdown {
      div {
        padding: 0px max(10px, 1vw);

        .text {
          flex: 1;

          span {
            font-size: max(10px, 1vw);
          }
        }
      }
    }

    .dropdown {
      display: flex;
      align-items: flex-start;
    }

    .single-language {
      width: 100% !important;
      padding: 0px !important;
      display: flex;

      * {
        padding: 0 !important;
      }

      .flag {
        width: max(30px, 1.5vw) !important;

        img {
          width: max(20px, 1vw);
          height: max(20px, 1vw);
        }
      }
    }

    img {
      width: max(20px, 1vw);
      height: max(20px, 1vw);
    }
  }
}

body.dark-mode {
  .lastFooterRight {
    display: flex;
  }
}

.shared-footer {
  .socialMediaLinks {
    a {
      background-color: transparent !important;
      width: max(40px, 2.35vw) !important;
      height: max(40px, 2.35vw) !important;
      margin: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid !important;
    }

    .socialMediaIcon {
      // height: max(20px, 1vw);
      height: 40%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .instagramIcon {
      content: url("../../../assets/nav/instagram.webp");
    }

    .facebookIcon {
      content: url("../../../assets/nav/facebook.webp");
    }

    .linkedinIcon {
      content: url("../../../assets/nav/linkedin.webp");
    }

    .twitterIcon {
      content: url("../../../assets/nav/twitter.svg");
    }

    .tiktokIcon {
      content: url("../../../assets/nav/tiktok.webp");
    }

    @media (max-width: 350px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
    }
  }
}

@media (max-width: 1400px) {
  .all-footer-links {
    width: 100% !important;

    .footerLink {
      text-align: start;
      width: 47.5%;
      max-width: 47.5%;
      margin: 15px auto;
    }
  }

  .shared-footer .last-row {
    margin: 0px;
    padding-top: 20px;
    gap: 10px;

    p {
      margin: 0px;
    }
  }
}

@media (max-width: 1020px) {
  .first-col {
    display: flex;
    align-items: center;

    .top {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .links1 {
      width: 40%;
      display: flex;
    }
  }

  .top {
    text-align: center;
  }

  .links1 {
    text-align: center;

    div {
      margin: 0 auto;

      img {
        height: 20px;
      }
    }
  }

  .shared-footer {
    .main-row .first-col {
      width: 100%;
      margin-bottom: 35px;

      .links1 a {
        width: 35px;
        height: 35px;
        font-size: 20px;
      }
    }

    .main-row .all-footer-links {
      width: 100%;
      margin: 0 auto;
    }
  }

  .last-row {
    display: flex;
    flex-direction: column;
    gap: max(30px, 0.5vw);
  }
}

@media (max-width: 768px) {
  .first-col {
    flex-direction: column !important;

    .Subscribe {
      width: 80%;
      margin: 0 auto;
    }

    .top {
      width: 100%;
      text-align: center !important;
    }

    img {
      align-self: center;
    }

    .links1 {
      width: 100%;
      align-items: center;
      gap: 10px;
    }
  }

  .all-footer-links {
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;

    .footerLink {
      text-align: start !important;
    }
  }
}

@media (max-width: 650px) {
  .shared-footer .first-col {
    text-align: center;

    .colors {
      flex-direction: column;
    }

    .links1 {
      justify-content: center;
    }

    .links1 a {
      margin: 5px;
    }
  }

  .shared-footer .all-footer-links {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;

    .footerLink {
      text-align: center;
      width: 47.5%;
      max-width: 47.5%;
      margin: 15px auto;
    }

    h6 {
      font-weight: 600;
    }
  }

  .shared-footer .last-row {
    flex-direction: column;

  
  }

  @media (max-width: 500px) {
    .Subscribe {
      width: 100% !important;
      margin: 0 auto;
    }

    .CallCard {
      display: flex;
      flex-direction: column !important;
      text-align: center !important;

      .right {
        text-align: center;
        span {
          width: 100%;
          font-size: max(12px, 0.7vw) !important;
        }
      }
    }

    .all-footer-links {
      width: 100%;
      display: flex !important;
      flex-direction: column;

      .footerLink {
        text-align: center !important;
      }
    }
  }
}