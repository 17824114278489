.GetInTouch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: max(50px, 2.5vw);

  .GetInTouch__top {
    width: 60%;
    position: relative;
    content: url("../../../assets/Home/GetInTouch/inLight.svg");

    @media (max-width: 1380px) {
      width: 85%;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .GetInTouch__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: max(30px, 1.5vw);
    padding: 0 max(10px, 0.5vw);

    margin: 0 auto;

    .TheFundation__icon {
      width: max(78px, 3.9vw);
      content: url("../../../assets/Home/TheFundation/theFundation.svg");
    }

    .with62 {
      font-size: max(62px, 3.1vw);
      font-family: "r-semibold";
      text-align: center;

      @media (max-width: 750px) {
        font-size: max(50px, 2.5vw);
      }
    }

    .with20 {
      text-align: center;
      font-size: max(18px, 0.9vw);
      line-height: max(33px, 1.75vw);
    }

    a {
      width: max(162px, 8vw);
      height: max(58px, 2.9vw);
      border-radius: 100vmax !important;
      color: #fff;
      font-family: "r-regular";
      font-size: max(15px, 0.75vw);
      background-color: #e2b10f;
      margin-top: max(30px, 1.5vw);
    }

    @media (max-width: 1380px) {
      width: 85%;
    }

    @media (max-width: 1000px) {
      width: 95%;
    }
  }
}

body.dark-mode {
  .GetInTouch__top {
    content: url("../../../assets/Home/GetInTouch/inDark.svg");
  }
}
