.nav .nav-megamenu {
  position: fixed;
  background: #fff;
  top: 4.75vw;
  overflow: hidden;
  display: none;
  justify-content: space-between;
  padding: max(15px, 1.5vw);
  border-radius: max(10px, 0.8vw);
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.15) 0px 3px 7px -3px;
  gap: max(20px, 1vw);
  left: max(10%, 20vw);

  .menu-links {
    display: flex;
    .inner-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

body.dark-mode .nav-megamenu {
  background-color: #08081d;
  box-shadow: rgba(185, 185, 192, 0.1) 0px 6px 12px -2px,
    rgba(250, 250, 250, 0.15) 0px 3px 7px -3px;
}

.nav .nav-megamenu .menu-main-link {
  display: flex;

  border: 1px solid transparent;
  overflow: hidden;
  border-radius: max(15px, 2vw);
  transition: 250ms all ease-in-out;

  &:hover {
    border: 1px solid #2ca253;
    border-radius: max(15px, 2vw);
    box-shadow: max(4px, 0.2vw) max(4px, 0.2vw) max(1px, 0.1vw) 0px
      rgba(44, 162, 83, 0.61);
  }

  &:hover .bg {
    width: 100%;
    height: 100%;
  }
  .main {
    background: transparent;
    width: 100%;
    position: relative;
    z-index: 3;
    flex: 1;
    padding: max(7px, 0.5vw) max(10px, 1vw);
    display: flex;
    align-items: center;
    outline: none !important;
    height: max(90px, 8vh);
  }
  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    transition: 0.35s ease-in-out;
    -webkit-transition: 0.35s ease-in-out;
    clip-path: polygon(
      calc(100% - max(24px, 2vw)) 0,
      100% max(19px, 1.7vw),
      100% 100%,
      0 100%,
      0 0
    );
  }
  .icon-container {
    width: max(22.5px, 1.6vw);
    max-height: max(22.5px, 1.6vw);
    margin-right: max(12.5px, 1vw);
    display: flex;
    align-items: center;
  }
  .icon {
    width: 100%;
    max-height: max(30px, 2.25vw);
  }
  .block {
    flex: 1;
  }
  h6 {
    font-size: max(12px, 0.8vw);
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    margin-bottom: max(3px, 0.6vw);
  }
  .block p {
    font-size: max(10px, 0.65vw);
    color: #3e3e3e;
    font-weight: 400;
    width: 80%;
  }
}

body.dark-mode .block {
  h6 {
    color: #fff !important;
  }

  p {
    color: #fff !important;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: max(20px, 1vw);

  h1 {
    font-size: max(48px, 3vw);
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: max(1px, 0.1vw) #000;
    span {
      display: flex;
      align-items: center;
      gap: max(20px, 1vw);
      -webkit-text-stroke: max(1px, 0.1vw) #2ca253;

      img {
        width: max(22px, 1.3vw);
      }
    }
  }

  p {
    width: max(70%, 80%);
    font-size: max(14px, 0.9vw);
  }

  button {
    width: 50%;
    min-width: 170px;
    height: max(60px, 3.1vw);
    font-size: max(12px, 0.8vw);
    border-radius: 100vmax;
    border: 0px;
    outline: none;
    background-color: #2ca253;
    color: #fff;
    font-weight: 600;
  }
}

body.dark-mode .right {
  h1 {
    -webkit-text-stroke: max(1px, 0.1vw) #fff;
  }
}

.featured-carousel {
  .sc-gLDymS {
    box-shadow: 0 0 1px 2px rgb(28, 28, 116);
  }

  .rec-dot_active {
    background-color: #1c1c74;
  }

  .erOlXv:focus {
    box-shadow: 0 0 1px 2px rgb(28, 28, 116);
  }

  .rec-arrow-right,
  .rec-arrow-left {
    display: none;
  }

  .projectsCard {
    display: flex;
    flex-direction: column;
    gap: max(15px, 0.75vw);

    .topImage {
      border-radius: max(10px, 0.5vw);
      min-width: max(240px, 12vw);
      aspect-ratio: 1/1;
    //   height: max(240px, 12vw);

    }

    p {
      font-size: max(15px, 0.75vw);
      font-family: "r-bold";
      color: #000;
    }
  }
}

.projects-megamenu {
  display: flex;
  flex-direction: column;
  gap: max(20px, 1vw);
  width: 75%;

  .viewAll {
    color: #e2b10f !important;
    svg {
      color: #e2b10f !important;
    }
  }

  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: max(50px, 2.5vw);

    p {
      font-size: max(13px, 0.65vw);
      font-family: "r-regular";
      font-weight: 100;
      text-transform: uppercase;
    }

    a {
      font-size: max(14px, 0.7vw);
      font-family: "r-medium";
      display: flex;
      align-items: center;
      gap: max(4px, 0.2vw);
      color: #e2b10f;
      font-weight: 400;

      svg {
        font-size: max(30px, 1vw);
      }
    }
  }
}

body.dark-mode {
  .projectsCard {
    p {
      color: #fff;
    }
  }

  .viewAll {
    color: #e2b10f !important;
    svg {
      color: #e2b10f !important;
    }
  }
}
