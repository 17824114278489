.Projects {
  display: flex;
  flex-direction: column;
  gap: max(60px, 3vw);

  .Projects__top {
    width: 100%;
    aspect-ratio: 1/0.32;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/Projects/projectsBg.webp");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    .Projects__top__filter {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: rgba(14, 14, 96, 0.44);
    }

    .with80 {
      position: relative;
      z-index: 2;
      font-size: max(80px, 4vw);
      font-family: "r-semibold";
      color: #fff;
    }

    @media (max-width: 900px) {
      padding: max(150px, 7.5vw) 0;

      .with80 {
        font-size: max(60px, 3vw);
      }
    }
  }

  .Projects__1 {
    display: flex;
    flex-direction: column;
    gap: max(40px, 2vw);
    align-items: center;
    justify-content: center;
    text-align: center;

    .with42 {
      font-size: max(42px, 2.1vw);
      font-family: "r-semibold";
    }

    .with18 {
      font-size: max(18px, 0.9vw);
      font-family: "r-regular";
      line-height: max(33px, 1.65vw);
      width: 70%;

      @media (max-width: 850px) {
        width: 100%;
      }
    }
  }

  .Projects__2 {
    padding: 0 8%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: max(20px, 1vw);

    .OurProjectsCard {
      width: 100% !important;
      max-width: none !important;
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .Projects__3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: max(60px, 3vw);

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: max(20px, 1vw);

      li {
        font-size: max(16px, 0.8vw);
        a {
          display: flex;
          align-items: center;
        }
        cursor: pointer;
      }
    }

    .previous {
      svg {
        font-size: max(20px, 1vw);
        color: #1c1c74;
        cursor: pointer;
      }
    }

    .next {
      svg {
        font-size: max(20px, 1vw);
        color: #1c1c74;
        cursor: pointer;
      }
    }

    .pagination__link--active {
      width: max(43px, 2.15vw);
      height: max(43px, 2.15vw);
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1c1c74;
      color: #fff;
    }
  }
}
