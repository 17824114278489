.OurProjectsCard {
  // max-width: max(400px, 20vw);
  aspect-ratio: 1/1.2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../assets/OurProjects/project1.webp");
  border-radius: max(10px, 0.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 90%;

  .OurProjectsCard__inside {
    width: 65%;
    gap: max(30px, 1.5vw);
    padding: max(30px, 1.5vw) 0;
    border-top: 6px solid #e2b10f;
    background-color: rgba(38, 38, 38, 0.79);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 250ms ease;
    opacity: 0;

    .with27 {
      font-size: max(15px, 1.35vw);
      font-family: "r-bold";
      color: #fff;
    }

    .with18 {
      font-size: max(12px, 0.9vw);
      font-family: "r-regular";
      display: flex;
      align-items: center;
      gap: 2px;
      color: #fff;

      svg {
        color: #fff;
      }
    }
  }

  &:hover {
    transition: all 250ms ease;

    .OurProjectsCard__inside {
      display: flex;
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}
