.OurServices {
  display: flex;
  flex-direction: column;
  gap: max(100px, 5vw);
  width: 100%;
  padding: 0 8%;

  .with62 {
    font-size: max(62px, 3.1vw);
    font-family: "r-semibold";
    text-align: center;

    @media (max-width: 750px) {
      font-size: max(50px, 2.5vw);
    }
  }

  .OurServices__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: max(40px, 2vw);

    a {
      width: max(195px, 9.75vw);
      height: max(67px, 3.35vw);
      border-radius: 100vmax !important;
      color: #fff;
      font-family: "r-regular";
      font-size: max(15px, 0.75vw);
      background-color: #1c1c74;
      margin-top: max(30px, 1.5vw);
    }

    .OurServices__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: max(50px, 2.5vw);
      width: 60%;

      .with20 {
        line-height: max(37px, 1.85vw);
      }

      @media (max-width: 1200px) {
        width: 70%;
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;

      .OurServices__left {
        width: 100%;
        .with62 {
          width: 100%;
        }
      }
    }
  }

  .OurServices__bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);


    .OurServices__InServiceCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: max(80px, 5vw);
        padding: 0 max(60px, 3vw);
        .with36 {
            font-size: max(36px, 1.8vw);
            font-family: 'r-medium';
        }

        a {
            font-size: max(18px, 0.9vw);
            font-family: 'r-medium';
            color: #E2B10F !important;
            display: flex;
            align-items: center;
            gap: max(5px, 0.25vw);

            svg {
                width: max(40px, 2vw);
                height: max(25px, 1.25vw);
                color: #E2B10F;
            }
        }
    }

    @media (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);

        .onlyToFillSpace{
            display: none;
        }

        .OurServices__InServiceCard {
            grid-column-start: 1;
            grid-column-end: 4;
            width: 100%;
            gap: max(20px, 1vw);
            margin: max(40px, 2vw) 0;
        }
    }

    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);

        .onlyToFillSpace{
            display: none;
        }

        .OurServices__InServiceCard {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 5;
            width: 100%;
            gap: max(20px, 1vw);
            margin: max(40px, 2vw) 0;
            padding: 0;
        }
    }
  

    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);

        .onlyToFillSpace{
            display: none;
        }

        .OurServices__InServiceCard {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
            width: 100%;
            gap: max(20px, 1vw);
            margin: max(40px, 2vw) 0;
            padding: 0;
        }
    }
  


}


}
