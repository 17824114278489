.YearsOfExperience {
  width: 100%;
  background-color: #0e0e2e;
  padding: 4% 0% 0% 8%;
  display: flex;
  flex-direction: column;
  gap: max(90px, 4.5vw);

  .YearsOfExperience__top {
    width: 60%;
    .with50 {
      font-size: max(40px, 2.5vw);
      font-family: "r-medium";
      color: #fff;
    }

    @media (max-width: 1400px) {
      width: 80%;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .YearsOfExperience__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .YearsOfExperience__left {
      display: flex;
      flex-direction: column;
      gap: max(50px, 2.5vw);

      .numberPlus {
        display: flex;
        gap: max(100px, 5vw);
        position: relative;
        z-index: 2;

        .firstOne {
          display: flex;
          flex-direction: column;
          gap: max(20px, 1vw);

          .with70 {
            font-size: max(50px, 3.5vw);
            font-family: "r-medium";
            color: #fff;
          }

          .with17 {
            font-size: max(17px, 0.85vw);
            font-family: "r-regular";
            text-transform: uppercase;
            color: #fff;
            width: max-content;
          }
        }

        @media (max-width: 500px) {
          flex-direction: column;
          gap: max(30px, 1.5vw);
        }
      }

      .yearsBorder {
        border-radius: max(40px, 2vw);
        background-color: #4675f6;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: max(10px, 0.5vw);
        padding: max(20px, 1vw) max(40px, 1vw);
        position: relative;
        z-index: 2;
        width: 115%;

        .with155 {
          font-size: max(120px, 7.75vw);
          font-family: "r-bold";
          color: #fff;
          transform: translateY(-10%);
          -webkit-text-fill-color: #0000;
          -webkit-text-stroke: max(1px, 0.1vw) #fff;
        }

        .with26 {
          font-size: max(18px, 1.3vw);
          font-family: "r-regular";
          text-transform: uppercase;
          color: #fff;
          font-weight: 100;
          width: max-content;
        }

        @media (max-width: 500px) {
          flex-direction: column;
        }
      }

      @media (max-width: 1350px) {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 10%;
        left: 0;

        .numberPlus {
          position: relative;
          z-index: 2;
        }
      }

      @media (max-width: 900px) {
        width: max-content;
      }

      @media (max-width: 700px) {
        width: 100%;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;

        .yearsBorder {
          width: 90%;
        }
      }
    }

    @media (max-width: 1350px) {
      justify-content: flex-end;
    }

    .YearsOfExperience__right {
      width: 70%;
      // aspect-ratio: 1/0.55;
      height: max(500px, 35vw);
      border-top-left-radius: max(70px, 3.5vw);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../assets/Home/YearsOfExperience/yearsOfExperienceBg.webp");
      position: relative;
      right: 0;
      bottom: 0;

      .YearsOfExperience__right__filter {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        border-top-left-radius: max(70px, 3.5vw);
      }

      @media (max-width: 1350px) {
        width: 80%;
      }

      @media (max-width: 900px) {
        width: 95%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }

      @media (max-width: 500px) {
        padding: max(100px, 5vw) 0;
        height: max(600px, 30vw);
      }
    }
  }

  @media (max-width: 700px) {
    padding-left: 0;
  }
}
