* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* width */
::-webkit-scrollbar {
  width: max(10px, 0.5vw);
}

/* Track */
::-webkit-scrollbar-track {
  background: #1C1C74;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5656a0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


a {
  text-decoration: none;
}

ul {
  list-style: none;
}

@font-face {
  font-family: 'r-medium';
  src: url('./fonts/Raleway-Medium.woff');
}

@font-face {
  font-family: 'r-regular';
  src: url('./fonts/Raleway-Regular.woff');
}

@font-face {
  font-family: 'r-semibold';
  src: url('./fonts/Raleway-SemiBold.woff');
}

@font-face {
  font-family: 'r-bold';
  src: url('./fonts/Raleway-Bold.woff');
}


.dcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}


.with20{
  font-size: max(20px, 1vw);
  font-family: 'r-regular';
}



.btnOnHover {
  border: none;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s linear;
  color: #fff;

  &:hover {
      border-radius: 0;
      transform: scale(1.05);
  }

  &::after {
      content: '';
      background: #3e3e91;
      width: 100%;
      height: 0%;
      border-radius: 100vmax !important;
      position: absolute;
      inset: 0;
      transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
      z-index: -1;
  }

  &:hover::after {
    height: 100%;
    width: 100%;
  }
}

.three::after {
  transition: all 0.2s linear;
}