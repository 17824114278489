.ContactUs {
  display: flex;
  flex-direction: column;
  gap: max(80px, 4vw);

  .nav {
    background-color: #fff;

    .logo_container {
      content: url("../../assets/shared/colorLogo.svg");
    }

    .inner-nav-link {
      color: #000 !important;
    }

    .CallCard {
      .icon {
        filter: brightness(0);
      }

      .right {
        * {
          color: #000;
        }
      }
    }

    .darkMode {
      filter: brightness(0);
    }

    .MobileNav {
      

      svg {
        color: #000 !important;
      }

      .left_mobile{
      * {
        color: #fff !important;
      }
      }


   
    }

  }

  .ContactUs__1 {
    width: 100%;
    padding: max(100px, 5vw) 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/ContactUs/contactBg.webp");
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: max(85px, 4.25vw);

    .ContactUs__1__filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(14, 14, 96, 0.31);
    }

    .ContactUs__1__content {
      width: 50%;
      padding: max(50px, 2.5vw) 0;
      margin-left: 8%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: max(60px, 4vw);
      background-color: rgba(28, 28, 116, 0.64);
      position: relative;
      z-index: 2;

      .with63 {
        font-size: max(63px, 3.1vw);
        font-family: "r-semibold";
        color: #fff;
      }

      @media (max-width: 900px) {
        width: 90%;

        .with63 {
          font-size: max(43px, 3.1vw);
        }
      }

      @media (max-width: 450px) {
        padding-left: max(10px, 0.5vw);
        margin-left: 0%;
        margin: 0 auto;
      }
    }
  }

  .ContactUs__2 {
    display: flex;
    justify-content: space-between;
    gap: max(50px, 2.5vw);
    padding: 0 8%;
    margin-bottom: max(100px, 5vw);

    .ContactUs__2__left {
      display: flex;
      flex-direction: column;
      gap: max(60px, 3vw);

      .top {
        display: flex;
        flex-direction: column;
        gap: max(15px, 0.75vw);
        width: 80%;

        .with35 {
          font-size: max(35px, 1.75vw);
          font-family: "r-semibold";
          text-align: start;
        }

        .with16 {
          font-size: max(16px, 0.8vw);
          font-family: "r-medium";
          line-height: 30px;
          text-align: start;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);

        .divWithInput {
          display: flex;
          flex-direction: column;
          gap: max(10px, 0.5vw);

          label {
            font-size: max(16px, 0.8vw);
            font-family: "r-semibold";
          }

          .fullInput {
            width: 100%;
            height: max(56px, 2.8vw);
            border-radius: 100vmax !important;
            border: 1px solid rgba(112, 112, 112, 0.2);
            position: relative;
            overflow: hidden;

            .iconInput {
              position: absolute;
              top: 50%;
              left: max(25px, 1.25vw);
              transform: translateY(-50%);
            }

            .iconInput1 {
              width: max(11px, 0.55vw);
              content: url("../../assets/ContactUs/user.svg");
            }

            .iconInput2 {
              width: max(15px, 0.75vw);
              content: url("../../assets/ContactUs/email.svg");
            }

            .iconInput3 {
              width: max(14px, 0.7vw);
              content: url("../../assets/ContactUs/phone.svg");
            }

            .iconInput4 {
              width: max(15px, 0.75vw);
              content: url("../../assets/ContactUs/briefcase.svg");
            }

            input,
            select,
            textarea {
              position: absolute;
              width: 100%;
              height: 100%;
              outline: none;
              border: none;
              font-size: max(14px, 0.7vw);
              font-family: "r-regular";
              padding-left: max(60px, 3vw);

              &::placeholder {
                color: #686868;
              }
            }

            select {
              padding-right: max(30px, 1.5vw); /* Adjust as needed */

              width: 97.5%;
              position: relative;
            }
          }

          textarea {
            border-radius: max(12px, 0.6vw) !important;
            height: max(180px, 9vw) !important;
            padding-left: max(30px, 1.5vw) !important;
            padding-top: max(20px, 1vw);
            border: 1px solid rgba(112, 112, 112, 0.2);
            outline: none;
          }
        }

        a {
          width: max(195px, 9.75vw);
          height: max(67px, 3.35vw);
          border-radius: 100vmax !important;
          color: #fff;
          font-family: "r-regular";
          font-size: max(15px, 0.75vw);
          background-color: #1c1c74;
          margin-top: max(30px, 1.5vw);
        }
      }
    }

    .ContactUs__2__right {
      display: flex;
      flex-direction: column;
      gap: max(50px, 2.5vw);

      .c2 {
        display: flex;
        flex-direction: column;
        gap: max(10px, 0.5vw);

        .with22 {
          font-size: max(22px, 1.1vw);
          font-family: "r-semibold";
        }

        span {
          font-size: max(14px, 0.7vw);
          font-family: "r-regular";
        }
      }

      .makeGroupCards {
        display: flex;
        flex-direction: column;
        gap: max(50px, 2.5vw);

        .CallCard {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: max(15px, 0.75vw);
          width: max-content;
          width: 100%;

          .left {
            width: max(46px, 2.25vw);
            height: max(46px, 2.25vw);
            aspect-ratio: 1/1 !important;
            border-radius: 100% !important;
            border: 1px solid #fff;
            display: flex;
            background: rgba(255, 255, 255, 0.27);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            background-color: #e2b10f;

            .icon {
              width: max(12.5px, 0.85vw);
              content: url("../../assets/shared/phone.svg");
            }

            .icon1 {
              width: max(12.5px, 0.85vw);
              content: url("../../assets/shared/email.svg");
            }

            .icon3 {
              width: max(12.5px, 0.85vw);
              content: url("../../assets/shared/location.svg");
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            gap: max(10px, 0.5vw);

            span {
              font-family: "r-regular";
              font-size: max(15px, 0.75vw);
              text-transform: uppercase;
              color: #000;
              font-weight: 100;
            }

            p {
              width: max(150px, 7.5vw);
              font-family: "r-bold";
              font-size: max(17px, 0.85vw);
              color: #000;
              font-weight: bold;
            }
          }
        }
      }

      .forIframe {
        width: max(400px, 20vw);
        aspect-ratio: 1/0.7;
        border-radius: max(15px, 0.75vw);
        overflow: hidden;

        @media (max-width: 1200px) {
          width: max(300px, 15vw);
        }
      }

      .iframeForMap {
        width: 100%;
        height: 100%;
        border: none !important;
        outline: none !important;
        overflow: hidden;
      }
    }
    @media (max-width: 800px) {
      flex-direction: column;

      .ContactUs__2__left {
        width: 100%;
      }

      .ContactUs__2__right {
        .forIframe {
          width: 100%;
        }
      }

      .CallCard {
        .right {
          width: 100%;

          p {
            width: 100% !important;
          }
        }
      }
    }

    @media (max-width: 500px) {
      .CallCard {
        .right {
          text-align: start;
        }
      }
    }
  }
}

body.dark-mode {
  .ContactUs {
    .nav {
      background-color: #0E0E21 ;

      .logo_container {
        content: url("../../assets/shared/logo.svg");
      }

      .inner-nav-link {
        color: #fff !important;
      }

      .CallCard {
        .icon {
          filter: brightness(100%);
        }

        .right {
          * {
            color: #fff;
          }
        }
      }

      .darkMode {
        filter: brightness(100%);
      }
    }

    .CallCard {
      .right {
        * {
          color: #fff !important;
        }
      }
    }

    input,
    select,
    textarea {
      background-color: #0E0E21 ;
      color: #fff;
    }

    .fullInput {
      background-color: #0E0E21 ;
    }
  }
}
