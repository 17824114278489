.CoursesMenuMobile {
  width: 100%;

  .itemMobile {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #fff !important;

    .main {
      font-size: 12px;
      border-bottom: 1px solid #fff !important;
      padding-left: max(10px, 0.5vw) !important;
      .icon {
        filter: brightness(1000%);
      }
    }

    .menu-main-link {
      &:hover {
        box-shadow: 1px 1px 9px 0px rgba(206, 235, 216, 0.1) inset;
      }

      .block {
        color: #000;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: max(14px, 1.2vw);

        h6 {
          font-size: max(12px, 1vw);
          font-family: 'r-regular';
          font-weight: lighter;
        }
      }
    }

    .main {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px 0px;

      .icon {
        width: max(18px, 0.9vw);
        height: max(18px, 0.9vw);
      }
    }
  }
}
