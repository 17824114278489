.TheFundation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: max(30px, 1.5vw);
  padding: 0 max(10px, 0.5vw);

  margin: 0 auto;

  .TheFundation__icon {
    width: max(78px, 3.9vw);
    content: url("../../../assets/Home/TheFundation/theFundation.svg");
  }

  .with62 {
    font-size: max(62px, 3.1vw);
    font-family: "r-semibold";
    text-align: center;

    @media (max-width: 750px) {
      font-size: max(50px, 2.5vw);
    }
  }

  .with20 {
    text-align: center;
    font-size: max(18px, 0.9vw);
    line-height: max(33px, 1.75vw);
  }

  a {
    width: max(195px, 9.75vw);
    height: max(67px, 3.35vw);
    border-radius: 100vmax !important;
    color: #fff;
    font-family: "r-regular";
    font-size: max(15px, 0.75vw);
    background-color: #1c1c74;
    margin-top: max(30px, 1.5vw);
  }

  @media (max-width: 1380px) {
    width: 85%;
  }

  @media (max-width: 1000px) {
    width: 95%;
  }
}
